import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FaFire } from 'react-icons/fa';

const DropDownCooking = () => {
    const cookingMethods = [
        { title: "marinade de poulet cuisson au barbecue", to: "/recette/cuisson/barbecue.html", text: "Barbecue" },
        { title: "marinade de poulet cuisson au plancha", to: "/recette/cuisson/plancha.html", text: "Plancha" },
        { title: "marinade de poulet cuisson au four", to: "/recette/cuisson/four.html", text: "Four" },
        { title: "marinade de poulet cuisson en friture", to: "/recette/cuisson/friture.html", text: "Friture" },
        { title: "marinade de poulet cuisson en poêle", to: "/recette/cuisson/poêle.html", text: "Poêle" },
        { title: "marinade de poulet cuisson à la cocotte", to: "/recette/cuisson/cocotte.html", text: "Cocotte" },
    ];

    let color = "rgba(255, 219, 88, 1)";
    const dropdownItemStyle = {
        zIndex: 1000,
        backgroundColor: color,
        color: 'black', // Texte noir
        fontWeight: "bolder",
        transition: 'background-color 0.3s ease', // Animation de transition pour le changement de couleur de fond
        border: "thin solid white",
    };

    return (
        <NavDropdown
            title={
                <div title="Rechercher selon un mode de cuisson" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <span
              className="dropDownLabel"
              style={{
                  marginTop: "5px",
                  color: color,
                  fontSize: "1.2rem", // Taille normale sur ordinateur
                  textAlign: "center"
              }}
          >
            Mode de cuisson
          </span>
                    <br />
                    <FaFire
                        style={{ height: "50px", width: "50px", color: color }}
                        className="d-none d-md-block" // Afficher seulement sur les écrans moyens et grands
                    />
                    <FaFire
                        style={{ height: "30px", width: "30px", color: color }}
                        className="d-block d-md-none" // Afficher seulement sur les petits écrans
                    />
                </div>
            }
            className="nav-dropdown-title"
        >
            {cookingMethods.map((method, index) => (
                <NavDropdown.Item as="a" key={index} href={method.to} style={dropdownItemStyle}>
                    <sup style={{ fontSize: "8px" }}>Marinade de poulet pour </sup>{method.text}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
}

export default DropDownCooking;
