import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FaDrumstickBite } from 'react-icons/fa';

const ChickenPartDropdown = () => {
  const chickenParts = [
    { title: "Cuisse de poulet", text: "Cuisse", to: "/recette/morceau/cuisse.html" },
    { title: "Pilon de poulet", text: "Pilon", to: "/recette/morceau/pilon.html" },
    { title: "Aile de poulet", text: "Aile", to: "/recette/morceau/aile.html" },
    { title: "Poulet entier", text: "Entier", to: "/recette/morceau/entier.html" },
    { title: "Manchon de poulet", text: "Manchon", to: "/recette/morceau/manchon.html" },
    { title: "Blanc de poulet", text: "Blanc", to: "/recette/morceau/blanc.html" },
  ];

  let color = "rgba(255, 105, 180, 1)";
  const dropdownItemStyle = {
    zIndex: 1000,
    backgroundColor: color,
    color: 'black', // Texte noir
    fontWeight: "bolder",
    transition: 'background-color 0.3s ease', // Animation de transition pour le changement de couleur de fond
    border: "thin solid white",
  };

  return (
      <NavDropdown
          title={
            <div title="Rechercher par type de morceaux" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <span
              className="dropDownLabel"
              style={{
                marginTop: "5px",
                color: color,
                fontSize: "1.2rem", // Taille normale sur ordinateur
                textAlign: "center"
              }}
          >
            L'aile ou la cuisse ?
          </span>
              <br />
              <FaDrumstickBite
                  style={{
                    height: "50px",
                    width: "50px",
                    color: color
                  }}
                  className="d-none d-md-block" // Afficher seulement sur les écrans moyens et grands
              />
              <FaDrumstickBite
                  style={{
                    height: "30px",  // Taille réduite sur mobile
                    width: "30px",
                    color: color
                  }}
                  className="d-block d-md-none" // Afficher seulement sur les petits écrans
              />
            </div>
          }
          className="nav-dropdown-title text-center"
      >
        {chickenParts.map((item, index) => (
            <NavDropdown.Item as="a" key={index} href={item.to} style={dropdownItemStyle}>
              <sup style={{ fontSize: "8px" }}>Marinade de poulet pour </sup>{item.text}<sup style={{ fontSize: "8px" }}> de poulet</sup>
            </NavDropdown.Item>
        ))}
      </NavDropdown>
  );
}

export default ChickenPartDropdown;
