import React, { Component } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Helmet } from "react-helmet";
import './About.css'; // Assurez-vous d'avoir les nouveaux styles
import { FaGlobe, FaFire, FaDrumstickBite, FaStar, FaSearch, FaArrowDown, FaApple, FaAndroid, FaWindows } from 'react-icons/fa'; // Ajout des icônes Apple, Android et Windows

import MainLogo from "../Medias/poulet-sans-fond-lettrage-b.png";
import { Link } from 'react-router-dom';

class About extends Component {
    constructor(props) {
        super(props);
        // Vérifier le localStorage pour voir si l'utilisateur a choisi "Je ne suis pas intéressé"
        const isNotInterested = localStorage.getItem('notInterested') === 'true';
        // Initialiser showInstallSection à true si l'application n'est pas installée et que l'utilisateur n'a pas choisi de ne pas être intéressé
        this.state = {
            showInstallSection: !isNotInterested && !window.matchMedia('(display-mode: standalone)').matches,
        };
    }

    hideInstallSection = () => {
        // Sauvegarder l'état dans le localStorage
        localStorage.setItem('notInterested', 'true');
        this.setState({ showInstallSection: false });
    }

    render() {
        const { showInstallSection } = this.state;

        return (
            <Jumbotron
                style={{
                    backgroundPosition: 'top',
                    backgroundSize: 'cover',
                    backgroundRepeat: "no-repeat",
                    background: "#fff",
                    marginTop: "-0px",
                    paddingTop: "0px",
                }}
                fluid
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Marinade de poulet - Un catalogue de recettes inratables</title>
                    <meta name="description" content="50 idées de recette pour faire une marinade de poulet" />
                </Helmet>

                <Container fluid style={{ backgroundColor: "#fff", marginTop: "20px" }}>
                    <Row className="section-1">
                        <Col md={{ span: 8, offset: 2 }} className="text-center">
                            <h1 className="display-4 mb-4" style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                                Trouvez ici votre recette de marinade de poulet
                            </h1>
                            <p className="lead">
                                Bienvenue sur Marinade-Poulet.com, votre catalogue de référence en ligne pour découvrir des recettes de marinades de poulet traditionnelles à travers le monde faciles et délicieuses !
                            </p>
                            {/* Nouvelle section pour PWA avec deux colonnes */}
                            {showInstallSection && (
                                <Row className="mt-4">
                                    <Col md={6} className="text-center">
                                        <p>
                                            <hr />
                                            <strong style={{ color: "darkorange" }}>Installez notre application pour une expérience optimale !</strong>
                                        </p>

                                        <div className="d-flex justify-content-center mt-3">
                                            {/* Bouton Installer */}
                                            <Link
                                                to="/installation"
                                                className="btn btn-primary btn-lg"
                                                title="Installer l'application"
                                                style={{ textDecoration: "none", padding: "10px 20px", marginRight: "10px" }}  // Supprimer le soulignement et ajouter du padding
                                                aria-label="Installer l'application marinade poulet com"  // Texte pour les lecteurs d'écran
                                            >
                                                Installer l'appli PWA <br />
                                                <FaAndroid size={20} />&nbsp; <FaApple size={20} />&nbsp; <FaWindows size={20} />
                                            </Link>

                                            {/* Bouton "Je ne suis pas intéressé" */}
                                            <Button
                                                variant="secondary"
                                                onClick={this.hideInstallSection}
                                                style={{ padding: "10px 20px" }}
                                            >
                                                Je ne suis pas intéressé
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <p style={{ color: "#000" }}>
                                            <hr />
                                            Découvrez notre application de recettes PWA ! Profitez d'un accès hors ligne à un vaste catalogue de recettes, des temps de chargement rapides et une interface réactive, idéale pour tous vos appareils et pour une cuisine encore plus facile et plus agréable !
                                        </p>
                                    </Col>
                                </Row>
                            )}

                            <hr />

                            <p><br />Voici comment utiliser l'application avec ses différentes manières de naviguer et parcourir notre catalogue de recette de poulet.<br />
                                <br /><FaArrowDown size={20} /></p>
                        </Col>
                    </Row>
                </Container>

                <Container style={{ backgroundColor: "#fff", marginTop: "-100px" }}>
                    <Row className="mt-5">
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#FFDB58" }}>
                                <Card.Body>
                                    <FaFire size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">1. Naviguez par Mode de Cuisson</Card.Title>
                                    <Card.Text>
                                        Choisissez une marinade en fonction du mode de cuisson que vous préférez.
                                    </Card.Text>
                                    <ul className="list-unstyled">
                                        <li><strong>Marinade pour Grill</strong> : Idéale pour les soirées d'été.</li>
                                        <li><strong>Marinade à la Plancha</strong> : Une cuisson rapide pour un goût délicieux.</li>
                                        <li><strong>Marinade au Four</strong> : Pour des morceaux de poulet juteux et savoureux.</li>
                                    </ul>
                                    <Link
                                        to="/poulet-mode-cuisson/marinades.html"
                                        title="Voir le catalogue de recettes pour tout mode de cuisson"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue de recettes pour tout mode de cuisson'
                                    >
                                        Voir le catalogue "tout mode de cuisson"
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#40E0D0" }}>
                                <Card.Body>
                                    <FaGlobe size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">2. Parcourez par Continent</Card.Title>
                                    <Card.Text>
                                        Découvrez des recettes en fonction de la cuisine régionale.
                                    </Card.Text>
                                    <ul className="list-unstyled">
                                        <li><strong>Cuisine Asiatique</strong> : Plongez dans des saveurs exotiques.</li>
                                        <li><strong>Cuisine du Moyen-Orient</strong> : Marinades riches en épices.</li>
                                        <li><strong>Cuisine Américaine</strong> : Recettes classiques pour vos barbecues.</li>
                                    </ul>
                                    <Link
                                        to="/poulet-dans-le-monde/marinades.html"
                                        title="Voir le catalogue de recettes du monde"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue de recettes du monde'
                                    >
                                        Voir le catalogue de recette "monde"
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#FF69B4" }}>
                                <Card.Body>
                                    <FaDrumstickBite size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">3. Choisissez Votre Morceau de Poulet Préféré</Card.Title>
                                    <Card.Text>
                                        Sélectionnez votre morceau de poulet préféré pour des recettes adaptées.
                                    </Card.Text>
                                    <ul className="list-unstyled">
                                        <li><strong>Blanc de Poulet</strong> : Léger et savoureux.</li>
                                        <li><strong>Cuisse de Poulet</strong> : Juteuse et riche en saveurs.</li>
                                        <li><strong>Aile et Pilon</strong> : Idéaux pour des apéritifs.</li>
                                    </ul>
                                    <Link
                                        to="/poulet-morceau-choisi/marinades.html"
                                        title="Voir le catalogue des marinades par morceau de poulet"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue des marinades par morceau de poulet'
                                    >
                                        Voir le catalogue par morceau
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#FFA500" }}>
                                <Card.Body>
                                    <FaStar size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">4. Rubrique Spéciale Crispy</Card.Title>
                                    <Card.Text>
                                        Découvrez des recettes pour un poulet doré et croustillant à chaque bouchée.
                                    </Card.Text>
                                    <Link
                                        to="/croustillant/crispys.html"
                                        title="Voir le catalogue des croustillants"
                                        className="btn btn-home"
                                        style={{ textDecoration: "none", padding: "10px 20px" }}
                                        aria-label='Voir le catalogue des croustillants'
                                    >
                                        Voir le catalogue des croustillants
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#28A745" }}>
                                <Card.Body>
                                    <FaSearch size={50} className="mb-3 text-dark" />
                                    <Card.Title className="text-dark">5. Recherche par Ingrédient</Card.Title>
                                    <Card.Text>
                                        Recherchez des recettes en fonction des ingrédients que vous souhaitez utiliser.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Logo Card avec animation */}
                        <Col md={4}>
                            <Card className="custom-card logo-card text-center">
                                <Card.Body>
                                    <img src={MainLogo} width="640" height="360" alt="catalogue de recettes de marinade de poulet" className="logo-img" />
                                    <div className="mt-4">
                                        <p>
                                            By :<a href='https://linktr.ee/drwily' title="site du développeur" style={{ width: "48px", height: "48px" }}> Dr.wil </a><br />
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="text-center mt-5">
                        <Col>
                            <p className="lead">
                                <strong style={{ color: "#fff" }}>Transformez vos repas dès aujourd'hui avec notre compilation de recettes de marinade de poulet !</strong>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}

export default About;
