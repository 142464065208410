import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Legal = () => {
    return (
        <Container fluid className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card className="p-4 shadow-sm">
                        <Card.Body>
                            <h2 className="mb-4 text-center">Informations Légales</h2>

                            <h5>1. Éditeur du site</h5>
                            <p>
                                <strong>Marinade-Poulet.com</strong><br />
                                Responsable de la publication : Wilfried DUBILLY<br />
                                Email : <a href="mailto:wilydub@gmail.com">contact</a>
                            </p>

                            <h5>2. Hébergement</h5>
                            <p>
                                Le site est hébergé par :<br />
                                <strong>Netlify</strong><br />
                                Netlify, dont le siège social est situé 2325 3rd Street, Suite 296, San Francisco, California 94107.
                                URL du site : www.netlify.com/
                                Contact : www.netlify.com/contact/
                            </p>

                            <h5>3. Propriété intellectuelle</h5>
                            <p>
                                L'ensemble des contenus (textes, images, vidéos, etc.) présents sur le site <strong>Marinade-Poulet.com</strong> est protégé par le droit d'auteur. Toute reproduction, représentation ou diffusion non autorisée des contenus est interdite, sauf accord écrit préalable.
                            </p>

                            <h5>4. Limitation de responsabilité</h5>
                            <p>
                                Le site <strong>Marinade-Poulet.com</strong> met à disposition des informations aussi précises que possible. Cependant, il ne saurait être tenu responsable des omissions, inexactitudes ou carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires.
                            </p>

                            <h5>5. Données personnelles</h5>
                            <p>
                                En conformité avec la loi "Informatique et Libertés" du 6 janvier 1978, vous disposez d’un droit d’accès, de modification et de suppression des données vous concernant. Vous pouvez exercer ce droit en envoyant un email à <a href="mailto:wilydub@gmail.com">@marinade-poulet.com</a>.
                            </p>

                            <h5>6. Contact</h5>
                            <p>
                                Pour toute question ou demande d'information complémentaire, n'hésitez pas à nous contacter à : <a href="mailto:wilydub@gmail.com">@marinade-poulet.com</a>.
                            </p>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Legal;
