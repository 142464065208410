import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = ({ onShowCookiePreferences }) => { // Ajout de la prop pour afficher les préférences cookies
    return (
        <div
            className="ml-auto fixed-bottom"
            style={{ color: "#888", fontSize: "12px", backgroundColor: "#111111", textAlign: "right" }}
        >
            <a
                href="/mentions-legales"
                title="Accéder aux mentions légales marniade poulet.com"
                style={{ color: "#888", width: "48px", height: "48px" }}
            >
                Mentions légales
            </a>

            &nbsp;&nbsp;|&nbsp;&nbsp;

            <button
                onClick={onShowCookiePreferences}
                style={{
                    color: "#888",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "12px",
                    padding: "0",
                }}
                title="Modifier les préférences cookies"
            >
                Préférences cookies
            </button>

            &nbsp;|&nbsp;<b>©2024 marinade-poulet.com</b>&nbsp;&nbsp;&nbsp;
        </div>
    );
};

export default Footer;
