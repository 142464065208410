import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import MarinadesDropdown from "./NavDropDownCountry";
import DropDownCooking from "./DropDownCooking";
import DropDownChickenPart from "./DropDownChickenPart";
import DropdownCrispy from './DropDownCrispy.js';
import Image from "react-bootstrap/Image";
import MainLogo from "../Medias/poulet-sans-fond-lettrage-b.png";
import {Link} from "react-router-dom";
import SelectTitle from "./SelectTitle";
import {FaDownload} from 'react-icons/fa';

function SearchForm() {
    return (
        <Container fluid style={{
            background: "linear-gradient(170deg, #000, #666)", // Dégradé du noir vers un gris foncé
            marginBottom: "0px",
            padding: "20px", // Ajouter du padding pour l'intérieur
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)" // Ombre pour donner de la profondeur
        }}>
            <Row className="align-items-center">
                <Col xs={12} md={4} className='d-flex flex-column align-items-center'>
                    {/* Titre au-dessus de l'image */}
                    <h1 style={{
                        marginBottom: "10px", // Espacement sous le titre
                        marginTop: "0px", // Réduire la marge supérieure
                        textAlign: "center", // Aligner le texte au centre
                        color: "white",
                        fontSize: "1em", // Taille du texte
                    }}>
                        Plus de 50 recettes traditionnelles de <br/><span style={{color: "orange"}}>marinades faciles à travers le monde</span>
                    </h1>

                    {/* Logo */}
                    <Link title="Revenir à l'accueil de marinade de poulet" to="/"
                          style={{zIndex: "1000", textDecoration: 'none', display: 'flex', alignItems: 'center'}}>
                        <Image
                            style={{
                                height: "100px", // Taille normale du logo sur grands écrans
                                width: "100px", // Taille normale du logo sur grands écrans
                                zIndex: "1000",
                                marginBottom: "10px", // Espacement sous le logo
                            }}
                            src={MainLogo}
                            alt="marinade-poulet.com"
                            className="d-none d-md-block" // Masquer sur les petits écrans
                        />
                        <Image
                            style={{
                                height: "50px", // Taille réduite du logo sur petits écrans
                                width: "50px", // Taille réduite du logo sur petits écrans
                                zIndex: "1000",
                                marginBottom: "10px", // Espacement sous le logo
                            }}
                            src={MainLogo}
                            alt="marinade-poulet.com"
                            className="d-block d-md-none" // Afficher sur les petits écrans
                        />
                    </Link>
                    <Link as="a" to="/installation" title="Installer la PWA marinade de poulet"
                          style={{color: "orange", fontSize: "0.9em", marginTop: "0px", textDecoration:"none", textAlign:"center"}}>
                        <i><span style={{color: "#fff"}}>Transformez votre poulet !</span><br/>
                            {/* Lien Installer l'application */}
                            Get your chicken Progressive Web App </i> <FaDownload
                        style={{color: "orange", marginLeft: "5px", fontSize: "0.7em"}}/>
                    </Link>
                </Col>

                <Col xs={12} md={8}>
                    <Row className="text-center">
                        <Col xs={6} md={3} className="text-center">
                            <DropDownCooking/>
                        </Col>
                        <Col xs={6} md={3} className="text-center">
                            <MarinadesDropdown/>
                        </Col>
                        <Col xs={6} md={3} className="text-center">
                            <DropDownChickenPart/>
                        </Col>
                        <Col xs={6} md={3} className="text-center">
                            <DropdownCrispy/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col><SelectTitle/></Col>
            </Row>
        </Container>
    );
}

export default SearchForm;
