import React, { useState, useEffect } from 'react';
import './CookieConsentGTM.css';
import { updateConsentSettings } from './gtm'; // Importer la fonction depuis gtm.js

const CookieConsentBanner = ({ onSaveConsent }) => {
    const [consentSettings, setConsentSettings] = useState({
        necessary: true, // Toujours activé
        analytics: true, // Initialement vrai, peut être chargé depuis localStorage
        marketing: false, // Initialement faux, sera chargé depuis localStorage si disponible
    });

    useEffect(() => {
        // Charger les paramètres de consentement depuis localStorage s'ils existent
        const storedConsentSettings = localStorage.getItem('cookieConsentSettings');
        if (storedConsentSettings) {
            setConsentSettings(JSON.parse(storedConsentSettings)); // Réinjecter les préférences sauvegardées
        }
    }, []); // Ne s'exécute qu'au premier rendu du composant

    const handleToggle = (category) => {
        setConsentSettings((prevState) => ({
            ...prevState,
            [category]: !prevState[category], // Inverser l'état de la catégorie spécifiée
        }));
    };

    const handleSave = () => {
        onSaveConsent(consentSettings); // Enregistrer les préférences
        localStorage.setItem('cookieConsentSettings', JSON.stringify(consentSettings)); // Sauvegarder les préférences dans localStorage
        updateConsentSettings(consentSettings); // Mettre à jour la dataLayer avec les paramètres de consentement
    };

    return (
        <div className="cookie-consent-overlay">
            <div className="cookie-consent-banner">
                <h4>Préférences de confidentialité</h4>
                <p>
                    Nous utilisons des cookies pour améliorer votre expérience de navigation.
                    Vous pouvez personnaliser vos préférences ci-dessous en activant ou désactivant certains types de cookies.
                </p>
                <ul>
                    <li>
                        <label>
                            <input type="checkbox" checked={consentSettings.necessary} disabled />
                            <strong>Cookies nécessaires</strong> (toujours activés) :
                            Ces cookies sont indispensables au fonctionnement du site. Ils permettent
                            des fonctionnalités de base comme la navigation sur la page et l'accès à des zones sécurisées.
                            Sans ces cookies, certaines parties du site ne fonctionneraient pas correctement.
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                checked={consentSettings.analytics}
                                onChange={() => handleToggle('analytics')}
                            />
                            <strong>Cookies analytiques</strong> :
                            Ces cookies collectent des informations sur la manière dont les visiteurs utilisent notre site.
                            Les données collectées sont anonymes et nous aident à améliorer les performances du site,
                            par exemple, en identifiant les pages les plus visitées ou les tendances générales de navigation.
                            Refuser ces cookies pourrait limiter notre capacité à optimiser votre expérience utilisateur.
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                checked={consentSettings.marketing}
                                onChange={() => handleToggle('marketing')}
                                disabled // Désactiver le checkbox pour les cookies marketing
                            />
                            <strong>Cookies marketing</strong> :
                            Ces cookies ne sont pas actuellement proposés par notre site. Ces derniers sont utilisés pour vous montrer des publicités pertinentes et personnalisées.
                            Ils permettent également de limiter le nombre de fois qu'une publicité vous est affichée
                            et de mesurer l'efficacité des campagnes publicitaires. Désactiver ces cookies entraînera
                            moins de publicités ciblées.
                        </label>
                    </li>
                </ul>
                <button className="cookie-btn" onClick={handleSave}>
                    Enregistrer mes préférences
                </button>
            </div>
        </div>
    );
};

export default CookieConsentBanner;
