import React from 'react';
import { FaStar } from 'react-icons/fa';

const CrispyStars = () => {
    let color = "rgba(255, 165, 0, 1)";
    return (
        <div className="nav-dropdown-title text-center">
            <a
                href={'/croustillant/crispys.html'}
                title={'Accès à la liste des recettes croustillantes de poulet'}
                style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", textDecoration: "none" }}
            >
        <span
            className="dropDownLabel"
            style={{
                marginTop: "10px",
                color: color,
                fontSize: "1.2rem", // Taille normale sur ordinateur
                textAlign: "center"
            }}
        >
          Les Crispys
        </span>
                <br />
                <FaStar
                    style={{ height: "50px", width: "50px", color: color }}
                    className="d-none d-md-block" // Afficher seulement sur les écrans moyens et grands
                />
                <FaStar
                    style={{ height: "30px", width: "30px", color: color }}
                    className="d-block d-md-none" // Afficher seulement sur les petits écrans
                />
            </a>
        </div>
    );
}

export default CrispyStars;
