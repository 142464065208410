import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Legal from "./Components/Legal";
import About from "./Components/About";
import SearchForm from "./Components/Search";
import { Container } from "react-bootstrap";
import ErrorBoundary from './Components/ErrorBoundary';
import LoadingOverlay from './Components/LoadingOverlay';
import ScrollToTop from './Components/ScrollToTop';
import CookieConsentGTM from './Components/CookieConsentGTM/CookieConsentGTM';

// Lazy loading pour ProjectDetails et ListeCategorie
const ProjectDetails = React.lazy(() => import('./Components/ProjectDetails'));
const ListeCategorie = React.lazy(() => import('./Components/ListeCategorie'));
const InstallApp = React.lazy(() => import('./Components/InstallApp'));

const App = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [gridSet, setGridSet] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deferredPrompt, setDeferredPrompt] = useState(null); // State pour l'événement de prompt

    const fetchSearchResults = () => {
        setSearchResults([]);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await import('./Medias/Gallery_0/GridSet.js');
            setGridSet(data.default);
            setLoading(false);
        };

        fetchData();
        fetchSearchResults();

        // Gérer l'événement beforeinstallprompt
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault(); // Prévenir le navigateur d'afficher le prompt par défaut
            setDeferredPrompt(e); // Enregistrer l'événement pour utilisation ultérieure
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', () => {});
        };
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <Container
                style={{
                    minHeight: "500px",
                    padding: "0px",
                }}
                fluid
            >
                <>
                    <CookieConsentGTM />
                    <SearchForm />

                    <ErrorBoundary>
                        <Suspense fallback={loading && <LoadingOverlay />}>
                            <Switch>
                                <Route exact path="/mentions-legales" component={Legal} />
                                <Route exact path="/" component={About} />
                                <Route exact path="/installation" render={() => <InstallApp deferredPrompt={deferredPrompt} />} />
                                {/* Vérification pour charger les projets seulement si gridSet n'est pas vide */}
                                {!loading && gridSet.map((project, i) => (
                                    <Route key={i} path={`${project.detailURL}`} component={() => <ProjectDetails fiche={project} />} />
                                ))}

                                <Suspense fallback={loading && <LoadingOverlay />}>
                                    <Route
                                        path="/categorie/recette/:categorie.html"
                                        render={(props) => (
                                            <ListeCategorie
                                                categorie={props.match.params.categorie}
                                                resultats={searchResults}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/recette/cuisson/:cuisine.html"
                                        render={(props) => (
                                            <ListeCategorie
                                                cuisine={props.match.params.cuisine}
                                                resultats={searchResults}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/recette/morceau/:morceau.html"
                                        render={(props) => (
                                            <ListeCategorie
                                                morceau={props.match.params.morceau}
                                                resultats={searchResults}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/croustillant/:crispys.html"
                                        render={(props) => (
                                            <ListeCategorie
                                                crispys={true}
                                                resultats={searchResults}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/poulet-dans-le-monde/marinades.html"
                                        render={() => (
                                            <ListeCategorie full="world" resultats={searchResults} />
                                        )}
                                    />
                                    <Route
                                        path="/poulet-mode-cuisson/marinades.html"
                                        render={() => (
                                            <ListeCategorie full="cuisson" cuisine={true} resultats={searchResults} />
                                        )}
                                    />
                                    <Route
                                        path="/poulet-morceau-choisi/marinades.html"
                                        render={() => (
                                            <ListeCategorie full="morceau" morceau={true} resultats={searchResults} />
                                        )}
                                    />
                                </Suspense>

                            </Switch>
                        </Suspense>
                    </ErrorBoundary>
                </>
            </Container>
        </Router>
    );
};

export default App;
