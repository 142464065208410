import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';

const MarinadesDropdown = () => {
  const marinades = [
    { title: "marinade de poulet au saveurs asiatiques", to: "/categorie/recette/asiatique.html", text: "Asie" },
    { title: "marinade de poulet au saveurs africaines", to: "/categorie/recette/afrique.html", text: "Afrique" },
    { title: "marinade de poulet au saveurs européenes", to: "/categorie/recette/europe.html", text: "Europe" },
    { title: "marinade de poulet au saveurs d'amérique du nord", to: "/categorie/recette/amerique-nord.html", text: "Amérique du nord" },
    { title: "marinade de poulet au saveurs d'amérique du sud", to: "/categorie/recette/amerique-sud.html", text: "Amérique du sud" },
    { title: "marinade de poulet au saveurs d'océanie", to: "/categorie/recette/oceanie.html", text: "Océanie" },
    { title: "marinade de poulet au saveurs du Moyen Orient", to: "/categorie/recette/moyen-orient.html", text: "Moyen-Orient" }
  ];

  let color = "rgba(64, 224, 208, 1)";

  const dropdownItemStyle = {
    zIndex: 1000,
    backgroundColor: color,
    color: 'black', // Texte noir par défaut
    fontWeight: "bolder",
    transition: 'background-color 0.3s ease', // Animation de transition pour le changement de couleur de fond
    border: "thin solid white",
  };

  return (
      <NavDropdown style={{ color: "black" }}
                   title={
                     <div title="Explorez les saveurs des continents" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <span
              className="dropDownLabel"
              style={{
                marginTop: "5px",
                color: color,
                fontSize: "1.2rem", // Taille normale sur ordinateur
                textAlign: "center"
              }}
          >
            Au 4 coins du monde
          </span>
                       <br />
                       <FaGlobe
                           style={{ height: "50px", width: "50px", color: color }}
                           className="d-none d-md-block" // Afficher seulement sur les écrans moyens et grands
                       />
                       <FaGlobe
                           style={{ height: "30px", width: "30px", color: color }}
                           className="d-block d-md-none" // Afficher seulement sur les petits écrans
                       />
                     </div>
                   }
                   className="nav-dropdown-title"
      >
        {marinades.map((item, index) => (
            <NavDropdown.Item as="a" key={index} href={item.to} style={dropdownItemStyle}>
              <sup style={{ fontSize: "8px" }}>Marinade de poulet en</sup> {item.text}
            </NavDropdown.Item>
        ))}
      </NavDropdown>
  );
}

export default MarinadesDropdown;
